body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.armakeup-indicator {
  position: absolute;
  left: 50%;
  top: 0px;
  width: 2px;
  background-image: linear-gradient(
    -180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 23%,
    rgba(255, 255, 255, 0.71) 76%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 2px 100%;
  padding: 0px 20px;
  background-position-x: 50%;
  cursor: ew-resize;
  display: block;
  resize: horizontal;
  height: 100%;
  margin-left: -20px;
  transform: translate(0px, 0px);
}

.armakeup-indicator-hide {
  display: none;
}

.armakeup-indicator-active {
  display: block;
}